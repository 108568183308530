import React from "react";
import { motion } from "framer-motion";
import { Phone, Mail, Sparkles } from 'lucide-react';

const Connectme = () => {
    return (
        <motion.div 
            className="w-screen min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-neutral-800 to-neutral-950 text-gray-300 p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <motion.div
                className="max-w-4xl w-full bg-gray-200 bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-8 mb-8 hover:text-amber-300"
                whileHover={{ scale: 1.02 }}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
            >
                <h1 className="text-4xl md:text-5xl text-center font-medium font-sans mb-6">
                    This is where your 
                    <span className="relative inline-block mx-2">
                        ideas 
                        <span className="absolute -top-6 left-1/2 transform -translate-x-1/4 text-2xl"></span>
                    </span> 
                    become 
                    <span className="relative inline-block mx-2">
                        reality
                        <span className="absolute -top-5 left-1/2 transform -translate-x-1/2 text-2xl"></span>
                    </span>
                </h1>
                
                <p className="text-center text-xl font-mono">
                    You're just one step away from turning your idea into reality.
                </p>
            </motion.div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl w-full ">
                <motion.div
                    className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg hover:text-amber-300 shadow-lg p-6"
                    whileHover={{ scale: 1.05 }}
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.3 }}
                >
                    <div className="flex items-center mb-4 ">
                        <Phone className="text-amber-400 mr-3" size={24} />
                        <h2 className="text-2xl font-semibold">Call Us</h2>
                    </div>
                    <a 
                        href="https://wa.me/+918147680744?text=Hello%20there!" 
                        className="text-xl  transition duration-200 ease-in-out"
                    >
                        +91 8147680744
                    </a>
                </motion.div>

                <motion.div
                    className="bg-white bg-opacity-10 backdrop-blur-md hover:text-amber-300 rounded-lg shadow-lg p-6 "
                    whileHover={{ scale: 1.05 }}
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.4 }}
                >
                    <div className="flex items-center mb-4">
                        <Mail className="text-amber-400 mr-3" size={24} />
                        <h2 className="text-2xl font-semibold">Email Us</h2>
                    </div>
                    <a 
                        href="mailto:email@rishabhrahul.com" 
                        className="text-xl  transition duration-200 ease-in-out"
                    >
                        work@rishabhrahul.com
                    </a>
                </motion.div>
            </div>

            <motion.div
                className="mt-8 text-center"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.5 }}
            >
                <div className="flex items-center justify-center">
                    <Sparkles className="text-amber-400 mr-2" size={20} />
                    <h2 className="text-xl font-semibold">Let's create something extraordinary from your vision!</h2>
                </div>
            </motion.div>
        </motion.div>
    );
}

export default Connectme;