import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';

const MermaidGraph = ({ graphDefinition }) => {
  const graphRef = useRef(null);

  useEffect(() => {
    if (!graphDefinition) {
      console.warn('Graph definition is empty or undefined');
      return;
    }

    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
      securityLevel: 'loose',
    });

    mermaid.render('mermaid-graph', graphDefinition)
      .then((result) => {
        if (graphRef.current) {
          graphRef.current.innerHTML = result.svg;
        }
      })
      .catch((error) => {
        console.error('Mermaid rendering error:', error);
      });
  }, [graphDefinition]);

  return <div ref={graphRef} />;
};

export default MermaidGraph;