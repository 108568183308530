import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Code, Lightbulb, Rocket, Brain } from 'lucide-react';
import java from "../java.svg";
import python from "../python.svg";
import reactjs from "../reactjs.svg";
import javascript from "../javascript.svg";
import kafka from "../kafka.svg";
import postgresql from "../postgresql.svg";
import springboot from "../springboot.svg";
import tailwindcss from "../tailwindcss.svg";
import flask from "../flask.svg";
import neo4j from "../neo4j.svg";
import qrd from "../qdrant.png";
import docker from "../docker1.svg";
import linux from "../linux1.svg";
import git from "../git1.svg";
const About = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const sections = useRef([]);
  const containerRef = useRef(null);

  useEffect(() => {
    sections.current = sections.current.slice(0, 3);
  }, []);

  // const scrollToNextSection = () => {
  //   if (currentSection < 2) {
  //     const nextSection = currentSection + 1;
  //     const yOffset = sections.current[nextSection].offsetTop;
  //     containerRef.current.scrollTo({
  //       top: yOffset,
  //       behavior: "smooth"
  //     });
  //     setCurrentSection(nextSection);
  //   }
  // };

  const scrollToSecondSection = () => {
    if (currentSection < 3) {
      const nextSection = 2;
      const targetSection = sections.current[nextSection];
      if (targetSection) {
        const yOffset = targetSection.offsetTop;
        containerRef.current.scrollTo({
          top: yOffset,
          behavior: "smooth"
        });
        setCurrentSection(nextSection);
      }
    }
  };

  const scrollToOneSection = () => {
    if (currentSection < 3) {
      const nextSection = 1;
      const targetSection = sections.current[nextSection];
      if (targetSection) {
        const yOffset = targetSection.offsetTop;
        containerRef.current.scrollTo({
          top: yOffset,
          behavior: "smooth"
        });
        setCurrentSection(nextSection);
      }
    }
  };
  const skills = [
    {
      icon: <Brain size={48} />,
      title: "AI Models & Vector Embedding",
      description: "Continuously learning and applying advanced AI models, vector embeddings, vector database to create intelligent systems. Experienced in leveraging large language models for accurate information retrieval and enhanced content understanding."
    },
    {
      icon: <Lightbulb size={48} />,
      title: "Innovative Problem Solver",
      description: "Passionate about finding innovative solutions to complex challenges. Skilled in applying machine learning techniques, system design, and data structures to drive digital transformation and build adaptable systems."
    },
    {
      icon: <Code size={48} />,
      title: "Full-Stack Developer",
      description: "Dedicated to crafting efficient and scalable applications. Proficient in DataBase, React, JavaScript, SpringBoot, Java, and Python, with a focus on integrating AI models into front-end and back-end systems."
    },
    {
      icon: <Rocket size={48} />,
      title: "Agile Project Leadership",
      description: "Committed to delivering high-quality projects through collaborative teamwork and agile methodologies. Experienced in leading cross-functional teams to transform businesses and improve user experiences."
    }
  ];

  return (
  
<div ref={containerRef} className=" md:pl-16  h-screen overflow-y-auto bg-gradient-to-b from-beige to-beige-dark text-gray-100 scroll-smooth">
      {/* Hero Section */}
      <motion.section
        ref={el => sections.current[0] = el}
        className="h-screen flex flex-col justify-center items-center text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div>
          <motion.h1
            className="text-4xl sm:text-6xl font-bold mb-4"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 100 }}
          >
            Innovating with Technology, Redefining Possibilities
          </motion.h1>
          <motion.p
            className="text-xl sm:text-2xl mb-8 "
            initial={{ y: 50 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 100, delay: 0.2 }}
          >
           Leveraging cutting-edge technologies and creative problem-solving, I transform visions into scalable and dynamic realities.
           <div className="mt-3 text-amber-200 font-mono">Tech Stack</div>
            <div className="flex flex-wrap justify-center items-center">
            <img className="h-20" src={java} alt="java-logo"/>
            <img src = {   springboot}  alt="Spring Boot"/>
            <img className="ml-2 mr-2" src={python} alt="Python"/>
            <img  className="h-20 ml-2 mr-2" src = {   flask} alt="Flask" />
            <img src={javascript} alt="JavaScript"/>
            <img className="h-20" src={reactjs} alt="ReactJS"/>
            <img src = {   tailwindcss} alt="TailwindCSS" />
         <img src = {   kafka} alt="Apache Kafka" />
         <img className="h-12"   src = {   neo4j} alt="Neo4J" />
         <img  className="ml-2 mr-2" src = {   postgresql} alt="PostgreSQL" />

 
     <img className="h-12 ml-2 mr-2" src = {   qrd} alt="Qdrant Vector Database" />

     <img className="h-16"   src = {   docker} alt="Docker"/>
     <img className="h-16" src = {   linux} alt="Linux" />
     <img className="h-16" src = {   git}  alt="Git"/>



</div>
          </motion.p>
          <motion.button
            className="bg-gray-100 text-gray-700 px-6 py-3 mt-16 rounded-full text-lg font-semibold hover:bg-amber-100 transition duration-900"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToOneSection}
          >
            Find Out What I Bring
          </motion.button>
        </div>
      </motion.section>

      {/* Skills Section */}
      <motion.section
        ref={el => sections.current[1] = el}
        className="min-h-screen py-20 flex flex-col justify-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold mb-10 text-center">What I Bring to the Table</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {skills.map((skill, index) => (
              <motion.div
                key={skill.title}
                className="bg-white bg-opacity-10 p-6 rounded-lg flex flex-col items-center text-center"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                <div className="text-amber-300 mb-4">{skill.icon}</div>
                <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-200 ">{skill.title}</h3>
                <p className=" text-gray-100 ">{skill.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
        {
          <div className="flex justify-center gap-4 mt-12">
            <motion.button
              className="bg-white text-neutral-900 px-8 py-4 rounded-full hover:bg-amber-100 transition-all duration-300 font-semibold"
              onClick={scrollToSecondSection}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
             Keep Exploring
            </motion.button>
            <motion.button
              className="bg-white text-gray-900 px-8 py-4 rounded-full hover:bg-amber-100 transition-all duration-900 font-semibold"
              onClick={()=>{window.location.href = "/contact";
                setCurrentSection(0);

               }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Let's Innovate Together
            </motion.button>
          </div>
        }
      </motion.section>

      {/* Call to Action */}
      <motion.section
        ref={el => sections.current[2] = el}
        className="min-h-screen py-20 flex flex-col justify-center text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6">Almost there!</h2>
          <p className="text-lg sm:text-xl mb-8">Explore advanced topics and deeper insights in the Builds and DSA sections!</p>
          {/* <motion.button
            className="bg-white text-neutral-800 px-8 py-4 rounded-full text-lg font-semibold hover:bg-amber-100 transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {window.location.href = "/contact"}}
          >
            Unleash Potential
          </motion.button> */}
                 <div className="flex justify-center gap-4 mt-12">
            <motion.button
              className="bg-white text-neutral-900 px-8 py-4 rounded-full hover:bg-amber-100 transition-all duration-300 font-semibold"
              onClick={()=>{window.location.href = "/dsa";
                setCurrentSection(0);

               }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Go to DSA
            </motion.button>
            <motion.button
              className="bg-white text-gray-900 px-8 py-4 rounded-full hover:bg-amber-100 transition-all duration-900 font-semibold"
              onClick={()=>{window.location.href = "/projects";
                setCurrentSection(0);

               }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Go to Builds
            </motion.button>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default About;