// import { useEffect, useState } from "react";
// import { motion,useAnimation } from "framer-motion";
// import logo from "../superlogo.svg";
// import { X , Menu,Blocks,House,FileText,Code, Mail} from 'lucide-react';
// const HeaderComponent = () => {
//   const [shouldAnimate, setShouldAnimate] = useState(() => {
//     return sessionStorage.getItem('hasAnimated') !== 'true';
//   });
//   const [selected, setSelected] = useState("");
//   const [isOpen, setIsOpen] = useState(false);

//   useEffect(() => {
//     if (shouldAnimate) {
//       sessionStorage.setItem('hasAnimated', 'true');
//     }
//   }, [shouldAnimate]);

//   useEffect(() => {
//     const sections = document.querySelectorAll("[data-section]");
//     const options = { threshold: 0.8 };

//     const callback = (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           setSelected(entry.target.dataset.section);
//         }
//       });
//     };

//     const observer = new IntersectionObserver(callback, options);
//     sections.forEach((section) => observer.observe(section));

//     return () => observer.disconnect();
//   }, []);

//   const navItems = [
//     { href: "/about", text: <div className="flex items-center">About<ul className="rotate-90 m-1">  <House /></ul></div>  },
//     { href: "/projects", text:  <div className="flex items-center " >Builds<ul className="rotate-90 m-1"> <Blocks/> </ul> </div> },
//     { href: "/resume", text: <div className="flex items-center " >Résumé<ul className="rotate-90 m-1"> <FileText /> </ul> </div>  },
//     {href:"/dsa-insight", text:<div className="flex items-center " >DSA<ul className="rotate-90 m-1"> <Code /> </ul> </div> },
//     {href:"/contact", text:<div className="flex items-center " >Contact<ul className="rotate-90 m-1"> <Mail /> </ul> </div> },
//     {href:"/", text: <div className="-m-5 rotate-90 w-48 h-32 p-3 sticky flex justify-center mt-10 "><img src={logo} alt="Logo" /></div>}
    
//   ];

//   return (

//     <div className="bg-gradient-to-r from from-indigo-50 to bg-purple-50">
//       {/* Toggle button for small screens */}
//       <button 
//         className="sm:hidden hover:scale-108 transition-transform duration-150 active:scale-95 fixed z-50 w-8 h-8 rounded-xl m-6 bg-gradient-to-t from-gray-50 to-indigo-50 "
//         onClick={() => setIsOpen(!isOpen)}
//       >

//         <div className="items-center p-1 ">{isOpen ? <X/> : <Menu/>}</div>
//       </button>

//       <motion.div 
//         className={` fixed top-0 left-0 h-full rounded-r-3xl bg-gradient-to-l   from-indigo-50 to bg-purple-50 z-40 
//                      flex flex-col   items-center justify-between 
//                     text-gray-900 font-mono hover:bg-indigo-200
//                     ${isOpen ? 'w-64' : 'w-0 sm:w-16'} 
//                     transition-all duration-300 ease-in-out`}
//         initial={shouldAnimate ? { x: -70 } : false}
//         animate={shouldAnimate ? { x: 0 } : false}
//         transition={shouldAnimate ? { duration: 0.5 } : {}}
//       >
      
//         <nav className="flex  flex-col w-full h-full overflow-hidden">
//           <div className="flex flex-col flex-grow">
//             <a className="h-10 flex-shrink-0  flex items-center justify-center 
//                           writing-mode-vertical-rl opacity-50 transition-all w-full"
//                onClick={() => {
//                  document.location.hash === "" ?
//                  document.getElementById("root")?.scrollIntoView() :
//                  document.location.hash = '';
//                }}>
             
//             </a>
               
//             {navItems.map((item, index) => (
//               <motion.a
//                 key={item.href}
//                 href={item.href}
//                 onClick={() => {
//                   setSelected(item.href.slice(1));
//                   setIsOpen(false);
//                 }}
//                 className={`h-28  flex-shrink-0 writing-mode-vertical-rl 
//                             text-sm font-semibold flex items-center justify-start 
//                             transition-all w-full hover:border-r-2 hover:opacity-100 hover:text-indigo-700
//                             ${selected === item.href.slice(1) ? 'text-indigo-600 opacity-100' : 'opacity-50'}`}
//                 initial={shouldAnimate ? { x: -70 } : false}
//                 animate={shouldAnimate ? { x: 0 } : false}
//                 transition={shouldAnimate ? { duration: 0.5, delay: 0.1 * (index + 1) } : {}}
//               >
//                 {item.text}
//               </motion.a>
//             ))}
//           </div>
//         </nav>
//       </motion.div>

//     </div>

//   );
// };

// export default HeaderComponent;
//============================================================================================

// import { useEffect, useState } from "react";
// import { motion, useAnimation } from "framer-motion";
// import logo from "../superlogo.svg";
// import { House, Blocks, FileText, Code, Mail } from 'lucide-react';

// const HeaderComponent = () => {
//   const [selected, setSelected] = useState("");
//   const [isOpen, setIsOpen] = useState(false);
//   const controls = useAnimation();

//   useEffect(() => {
//     const sections = document.querySelectorAll("[data-section]");
//     const options = { threshold: 0.8 };

//     const callback = (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           setSelected(entry.target.dataset.section);
//         }
//       });
//     };

//     const observer = new IntersectionObserver(callback, options);
//     sections.forEach((section) => observer.observe(section));

//     return () => observer.disconnect();
//   }, []);

//   const navItems = [
//     { href: "/about", text: <div className="flex items-center">About<House className="ml-2" /></div> },
//     { href: "/projects", text: <div className="flex items-center">Builds<Blocks className="ml-2" /></div> },
//     { href: "/resume", text: <div className="flex items-center">Résumé<FileText className="ml-2" /></div> },
//     { href: "/dsa-insight", text: <div className="flex items-center">DSA<Code className="ml-2" /></div> },
//     { href: "/contact", text: <div className="flex items-center">Contact<Mail className="ml-2" /></div> },
//   ];

//   const handleDragEnd = (event, info) => {
//     if (info.offset.x > 50 && !isOpen) {
//       setIsOpen(true);
//       controls.start({ x: 0 });
//     } else if (info.offset.x < -50 && isOpen) {
//       setIsOpen(false);
//       controls.start({ x: "-100%" });
//     } else {
//       controls.start({ x: isOpen ? 0 : "-100%" });
//     }
//   };

//   return (
//     <>
//       {/* Always visible sidebar for larger screens */}
//       <div className="hidden sm:block fixed top-0 left-0 h-full w-16 bg-gradient-to-b from-indigo-50 to-purple-50">
//         <nav className="flex flex-col items-center h-full py-4">
//           <img src={logo} alt="Logo" className="w-10 h-10 mb-8" />
//           {navItems.map((item) => (
//             <a
//               key={item.href}
//               href={item.href}
//               className={`w-full py-4 flex justify-center ${
//                 selected === item.href.slice(1) ? 'text-indigo-600' : 'text-gray-600'
//               } hover:text-indigo-700`}
//             >
//               {item.text.props.children[1]}
//             </a>
//           ))}
//         </nav>
//       </div>

//       {/* Swipeable sidebar for small screens */}
//       <motion.div 
//         className="sm:hidden fixed top-0 left-0 h-full w-64 bg-gradient-to-b from-indigo-50 to-purple-50 z-50 shadow-lg"
//         initial={{ x: "-100%" }}
//         animate={controls}
//         drag="x"
//         dragElastic={0.1}
//         dragConstraints={{ left: 0, right: 0 }}
//         onDragEnd={handleDragEnd}
//       >
//         <nav className="flex flex-col h-full py-4">
//           <img src={logo} alt="Logo" className="w-16 h-16 mx-auto mb-8" />
//           {navItems.map((item) => (
//             <a
//               key={item.href}
//               href={item.href}
//               className={`px-6 py-4 ${
//                 selected === item.href.slice(1) ? 'bg-indigo-100 text-indigo-600' : 'text-gray-600'
//               } hover:bg-indigo-50 hover:text-indigo-700`}
//               onClick={() => setIsOpen(false)}
//             >
//               {item.text}
//             </a>
//           ))}
//         </nav>
//       </motion.div>

//       {/* Swipe area for small screens */}
//       <div 
//         className="sm:hidden fixed top-0 left-0 w-8 h-full z-40"
//         onTouchStart={(e) => {
//           const touch = e.touches[0];
//           if (touch.clientX < 20 && !isOpen) {
//             setIsOpen(true);
//             controls.start({ x: 0 });
//           }
//         }}
//       />
//     </>
//   );
// };

// export default HeaderComponent;

//=============================================
// import { useEffect, useState } from "react";
// import { motion, useAnimation } from "framer-motion";
// import logo from "../superlogo.svg";
// import { House, Blocks, FileText, Code, Mail } from 'lucide-react';

// const HeaderComponent = () => {
//   const [selected, setSelected] = useState("");
//   const [isOpen, setIsOpen] = useState(false);
//   const [isHovered, setIsHovered] = useState(false);
//   const controls = useAnimation();

//   useEffect(() => {
//     const sections = document.querySelectorAll("[data-section]");
//     const options = { threshold: 0.8 };

//     const callback = (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           setSelected(entry.target.dataset.section);
//         }
//       });
//     };

//     const observer = new IntersectionObserver(callback, options);
//     sections.forEach((section) => observer.observe(section));

//     return () => observer.disconnect();
//   }, []);


//   const navItems = [
//     { href: "/about", text: <div className="rotate-90 flex items-center">About<ul className=" m-1">  <House /></ul></div>  },
//     { href: "/projects", text:  <div className="flex items-center " >Builds<ul className="rotate-90 m-1"> <Blocks/> </ul> </div> },
//     { href: "/resume", text: <div className="flex items-center " >Résumé<ul className="rotate-90 m-1"> <FileText /> </ul> </div>  },
//     {href:"/dsa-insight", text:<div className="flex items-center " >DSA<ul className="rotate-90 m-1"> <Code /> </ul> </div> },
//     {href:"/contact", text:<div className="flex items-center " >Contact<ul className="rotate-90 m-1"> <Mail /> </ul> </div> },
//     {href:"/", text: <div className="-m-5 rotate-90 w-48 h-32 p-3 sticky flex justify-center mt-10 "><img src={logo} alt="Logo" /></div>}
    
//   ];
//   const handleDragEnd = (event, info) => {
//     if (info.offset.x > 30 && !isOpen) {
//       setIsOpen(true);
//       controls.start({ x: 0 });
//     } else if (info.offset.x < -50 && isOpen) {
//       setIsOpen(false);
//       controls.start({ x: "-100%" });
//     } else {
//       controls.start({ x: isOpen ? 0 : "-100%" });
//     }
//   };

//   return (
//     <>
//       {/* Expandable sidebar for larger screens */}
//       <motion.div 
//         className="hidden sm:block fixed top-0 left-0 h-full bg-gradient-to-b from-indigo-50 to-purple-50 shadow-lg z-50"
//         initial={{ width: "4rem" }}
//         animate={{ width: isHovered ? "16rem" : "4rem" }}
//         transition={{ duration: 0.3 }}
//         onMouseEnter={() => setIsHovered(true)}
//         onMouseLeave={() => setIsHovered(false)}
//       >
//         <nav className="flex flex-col items-center h-full py-4">
//           <img src={logo} alt="Logo" className="w-10 h-10 mb-8" />
//           {navItems.map((item) => (
//             <motion.a
//               key={item.href}
//               href={item.href}
//               className={`w-full py-4 px-4 flex items-center ${
//                 selected === item.href.slice(1) ? 'text-indigo-600' : 'text-gray-600'
//               } hover:text-indigo-700 hover:bg-indigo-100 rounded-l-full transition-colors`}
//               whileHover={{ x: 5 }}
//             >
//               <span className="mr-4">{item.icon}</span>
//               <span className={`whitespace-nowrap ${isHovered ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
//                 {item.text}
//               </span>
//             </motion.a>
//           ))}
//         </nav>
//       </motion.div>

//       {/* Swipeable sidebar for small screens */}
//       <motion.div 
//         className="sm:hidden fixed top-0 left-0 h-full w-64 bg-gradient-to-b from-indigo-50 to-purple-50 z-50 shadow-lg"
//         initial={{ x: "-100%" }}
//         animate={controls}
//         drag="x"
//         dragElastic={0.1}
//         dragConstraints={{ left: 0, right: 0 }}
//         onDragEnd={handleDragEnd}
//       >
//         <nav className="flex flex-col h-full py-4">
//           <img src={logo} alt="Logo" className="w-16 h-16 mx-auto mb-8" />
//           {navItems.map((item) => (
//             <a
//               key={item.href}
//               href={item.href}
//               className={`px-6 py-4 flex items-center ${
//                 selected === item.href.slice(1) ? 'bg-indigo-100 text-indigo-600' : 'text-gray-600'
//               } hover:bg-indigo-50 hover:text-indigo-700`}
//               onClick={() => setIsOpen(false)}
//             >
//               <span className="mr-4">{item.icon}</span>
//               {item.text}
//             </a>
//           ))}
//         </nav>
//       </motion.div>

//       {/* Swipe area for small screens */}
//       <div 
//         className="sm:hidden fixed top-0 left-0 w-8 h-full z-40"
//         onTouchStart={(e) => {
//           const touch = e.touches[0];
//           if (touch.clientX < 20 && !isOpen) {
//             setIsOpen(true);
//             controls.start({ x: 0 });
//           }
//         }}
//       />
//     </>
//   );
// };

// export default HeaderComponent;
import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import logo from "../superlogo.svg";
import { House, Blocks, FileText, Code, Mail, ChevronsRight } from 'lucide-react';

const HeaderComponent = () => {
  const [selected, setSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const sections = document.querySelectorAll("[data-section]");
    const options = { threshold: 0.4 };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setSelected(entry.target.dataset.section);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  const navItems = [
    { href: "/about", text: "About", icon: <House size={20} /> },
    { href: "/projects", text: "Builds", icon: <Blocks size={20} /> },
   
    { href: "/dsa", text: "DSA", icon: <Code size={20} /> },
    { href: "/contact", text: "Contact", icon: <Mail size={20} /> }
  ];

  const handleDragEnd = (event, info) => {
    if (info.offset.x > 30 && !isOpen) {
      setIsOpen(true);
      controls.start({ x: 0 });
    } else if (info.offset.x < -50 && isOpen) {
      setIsOpen(false);
      controls.start({ x: "-100%" });
    } else {
      controls.start({ x: isOpen ? 0 : "-100%" });
    }
  };

  return (
    <>
      {/* Expandable sidebar for larger screens */}
      <motion.div 
        className="hidden sm:block fixed top-0 left-0 h-full bg-gray-200 shadow-lg z-50 rounded-r-xl"
        initial={{ width: "3rem" }}
        animate={{ width: isHovered ? "12rem" : "3rem" }}
        transition={{ duration: 0.3 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <nav className="h-full py-4 flex flex-col items-start justify-start">
          {navItems.map((item) => (
            <motion.a
              key={item.href}
              href={item.href}
              className={`w-full py-8 px-4 flex items-center
                ${selected === item.href.slice(1) ? 'text-gray-600' : 'text-gray-900'}
                hover:text-gray-700 hover:bg-neutral-400 rounded-l-full font-semibold transition-colors`}
              whileHover={{ x: 5 }}
            >
              <div className="flex items-center w-full">
                <span className={`flex-shrink-0 ${isHovered ? 'mr-4' : ''}`}>
                  {item.icon}
                </span>
                <motion.span 
                  className="whitespace-nowrap overflow-hidden"
                  initial={{ width: 0, opacity: 0 }}
                  animate={{ 
                    width: isHovered ? 'auto' : 0, 
                    opacity: isHovered ? 1 : 0,
                    transformOrigin: 'left center',
                    rotate: isHovered ? 0 : 90
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {item.text}
                </motion.span>
              </div>
            </motion.a>
          ))}
        </nav>
      </motion.div>

      {/* Swipeable sidebar for small screens */}
      <motion.div 
        className="sm:hidden fixed top-0 left-0 h-full w-64 bg-gradient-to-b from-amber-50 to-amber-100 z-50 shadow-lg justify-center items-center"
        initial={{ x: "-100%" }}
        animate={controls}
        drag="x"
        dragElastic={0.1}
        dragConstraints={{ left: 0, right: 0 }}
        onDragEnd={handleDragEnd}
      >
        <nav className="flex flex-col h-full py-4">
          {navItems.map((item) => (
            <a
              key={item.href}
              href={item.href}
              className={`px-6 py-4 flex items-center ${
                selected === item.href.slice(1) ? 'bg-indigo-100 text-gray-600' : 'text-gray-600'
              } hover:bg-indigo-50 hover:text-gray-700 font-bold`}
              onClick={() => setIsOpen(false)}
            >
              <span className="mr-4">{item.icon}</span>
              {item.text}
            </a>
          ))}
        </nav>
      </motion.div>

      {/* Swipe area for small screens */}
      <motion.div 
        className="sm:hidden fixed top-1/2 left-0 -translate-y-1/2 w-8 h-16  bg-gradient-to-r from-amber-400 to-transparent rounded-r-md flex items-center justify-center z-30 cursor-pointer"
        animate={{ x: isOpen ? "-100%" : 0 }}
       

        onTouchStart={(e) => {
          const touch = e.touches[0];
          if (touch.clientX < 20 && !isOpen) {
            setIsOpen(true);
            controls.start({ x: 0 });
          }
        }}

      >
        {/* <div className="text-white">
          <ChevronsRight size={24} />
        </div> */}
      </motion.div>
    </>
  );
};

export default HeaderComponent;