import { useEffect, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useDataStructureOneAPICallForAll } from "../RQuery";
import { motion } from "framer-motion";
const DSACards = () => {
  const { data: apiResponse, isLoading, isError } = useDataStructureOneAPICallForAll();
  const [contentData, setContentData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (apiResponse?.data) {
      setContentData(apiResponse.data);
    } else {
      console.error("Received data is not in the expected format:", apiResponse);
      setContentData([]);
    }
  }, [apiResponse]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  const handleViewDetails = (item) => {
    window.open(`/dsa/${item.sys.id}`, '_blank');
  };

  return (
    <div className="bg-gradient-to-b from-neutral-800 to-neutral-950  h-screen overflow-hidden">
      <div className="h-full overflow-y-scroll">
        <div className="pt-16 sm:pt-8 md:pt-4 lg:pt-0"> {/* Adjust top padding for navbar */}
          <div className="ml-2 sm:ml-4 md:ml-6 lg:ml-10 p-4 sm:p-8 md:p-16 lg:p-32">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {contentData?.map((item) => (
                <motion.div
                key={item.sys.id}
                className="p-4 bg-white text-neutral-300 bg-opacity-20 backdrop-blur-lg rounded-3xl overflow-hidden shadow-lg cursor-pointer"
                whileHover={{ scale: 1.05 }}
                onClick={() => handleItemClick(item)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                  <img className="w-full h-48 object-cover rounded-xl" src={item?.fields?.media?.fields?.file?.url} alt={item?.fields?.title} />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2 truncate">{item?.fields?.title}</div>
                    <p className="text-neutral-300 text-base truncate"></p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
     
      {selectedItem && (
        <motion.div
          className="fixed inset-0 bg-beige bg-opacity-50 overflow-y-auto h-full w-full z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }} // Smooth fade-in and fade-out effect
        >
          <motion.div
            className="relative top-20 mx-auto p-5 border w-11/12 sm:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-beige bg-opacity-30 backdrop-blur-xl"
            initial={{ scale: 0.8, opacity: 0 }} // Starting animation for modal
            animate={{ scale: 1, opacity: 1 }} // Zoom in effect
            transition={{ duration: 0.4 }} // Smooth transition
          >
            <div className="mt-3">
              <h3 className="text-lg leading-6 font-medium text-gray-100">{selectedItem.fields.title}</h3>
              <div className="mt-2 px-4 py-3">
                <p className="text-sm text-gray-300">
                {documentToReactComponents( selectedItem.fields?.introduction)}
                </p>
              </div>
              <div className="mt-4 flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
              
                <button
                  onClick={handleCloseModal}
                  className="px-4 py-2 bg--600 bg-beige text-neutral-300 text-base font-medium rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-neutral-300"
                >
                  Close
                </button>
                <button
                  onClick={() => handleViewDetails(selectedItem)}
                  className="px-4 py-2 bg-amber-600 text-neutral-300 text-base font-medium rounded-md shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-900"
                >
                  View Details
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}


   
    </div>
  );
};

export default DSACards;