import { useQueries, useQuery } from "@tanstack/react-query";
import { client } from "./client";
import axios from "axios";


// about section has be one and only
export function useAboutSectionSingleAPICall(){
    return useQuery(
        {
            queryKey:['projects'],
            queryFn:async ()=>{
                const response = await client.getEntries({
                content_type:'projects'
            });
            console.log(response)
            return {data: response?.items};

            
        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});

} 

// about section has be one and only
export function useProjectSectionSingleAPICall(){
    return useQuery(
        {
            queryKey:['pastProjectAsArticle'],
            queryFn:async ()=>{
                const response = await client.getEntries({
                content_type:'pastProjectAsArticle'
            });
            console.log("new response", response)
            console.log("new response with items", response?.items)
            return {data: response?.items};


        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});

} 

export function useDSALifestyleSingleAPICall(){
    return useQuery(
        {
            queryKey:['dsa'],
            queryFn:async ()=>{
                const response = await client.getEntries({
                content_type:'dsa'
            });
            console.log("new response lifestyle", response)
            console.log("new response with items", response?.items)
            return {data: response?.items};


        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});
}


export function useProjectSectionAPICallById(enteryID){
    return useQuery(
        {
            queryKey:['pastProjectAsArticle',enteryID],
            queryFn:async ()=>{
                const response = await client.getEntry(enteryID);
            console.log("new response by ID", response)
            //console.log("new response with items", response?.items)
            return {data: response};


        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});

} 



export function useLifeStyleAPICallById(enteryID){
    return useQuery(
        {
            queryKey:['lifestyle',enteryID],
            queryFn:async ()=>{
                const response = await client.getEntry(enteryID);
            console.log("new response by ID", response)
            //console.log("new response with items", response?.items)
            return {data: response};


        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});
};
export function useResearchDSAAPICallById(enteryID){
    return useQuery(
        {
            queryKey:['dsa',enteryID],
            queryFn:async ()=>{
                const response = await client.getEntry(enteryID);
            console.log("new response by ID for DSA", response)
            //console.log("new response with items", response?.items)
            return {data: response};


        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});
};
 

// builds

export function useBuildsOneAPICallForAll(){
    return useQuery(
        {
            queryKey:['builds'],
            queryFn:async ()=>{
                const response = await client.getEntries({
                content_type:'builds'
            });
            console.log("new response Builds", response)
            console.log("new response with Builds", response?.items)
            return {data: response?.items};


        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});
}
// datastructure

export function useBuildsAPICallById(enteryID){
    return useQuery(
        {
            queryKey:['builds',enteryID],
            queryFn:async ()=>{
                const response = await client.getEntry(enteryID);
            console.log("new response for builds by ID", response)
            
            return {data: response};


        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});

} 



export function useDataStructureOneAPICallForAll(){
    return useQuery(
        {
            queryKey:['datastructure'],
            queryFn:async ()=>{
                const response = await client.getEntries({
                content_type:'datastructure'
            });
            console.log("new response datastructure", response)
            console.log("new response with datastructure", response?.items)
            return {data: response?.items};


        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});
}
// datastructure

export function useDataStructureAPICallById(enteryID){
    return useQuery(
        {
            queryKey:['datastructure',enteryID],
            queryFn:async ()=>{
                const response = await client.getEntry(enteryID);
            console.log("new response for datastructure by ID", response)
            
            return {data: response};


        },
        staleTime: 30 * 60 * 1000, // Data remains fresh for 30 minutes
        cacheTime: 60 * 60 * 1000, // Data stays in cache for 1 hour
});

} 