import React,{useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import Page from "./Page";
import { useBuildsAPICallById } from "../RQuery";
import { motion } from 'framer-motion';
const  LayoutTest=() =>{
  const { id } = useParams();
    const { data: apiResponse, isLoading, isError } = useBuildsAPICallById(id);
    const [contentData, setContentData] = useState([]);
    useEffect(() => {
        if (apiResponse?.data) {
          setContentData(apiResponse?.data);
          console.log("testData", contentData?.fields?.title)
        } else {
          console.error("Received data is not in the expected format:", apiResponse);
          setContentData([]);
        }
      }, [apiResponse]);



      if (isLoading ) {

        return (   <div className="flex flex-col h-screen bg-neutral-800 text-gray-100 p-4">
          <motion.div
            className="bg-neutral-900 rounded-md shadow-md p-4 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="h-8 bg-neutral-700 rounded-md mb-4 animate-pulse"></div>
            <div className="h-4 bg-neutral-700 rounded-md w-3/4 mb-2 animate-pulse"></div>
            <div className="h-4 bg-neutral-700 rounded-md w-1/2 animate-pulse"></div>
          </motion.div>
    
          <div className="flex flex-1">
            <motion.div
              className="w-1/6 bg-neutral-900 rounded-md shadow-md mr-4 animate-pulse"
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: "16.67%" }}
              transition={{ duration: 0.5 }}
            ></motion.div>
    
            <motion.div
              className="flex-1 bg-neutral-900 rounded-md shadow-md p-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {[...Array(5)].map((_, index) => (
                <div key={index} className="mb-4">
                  <div className="h-4 bg-neutral-700 rounded-md w-full mb-2 animate-pulse"></div>
                  <div className="h-4 bg-neutral-700 rounded-md w-3/4 animate-pulse"></div>
                </div>
              ))}
            </motion.div>
    
            <motion.div
              className="w-1/6 bg-neutral-900 rounded-md shadow-md ml-4 animate-pulse"
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: "16.67%" }}
              transition={{ duration: 0.5 }}
            ></motion.div>
          </div>
        </div>);
       
        
          
      }
      
    return (
    <div>
        <Page article={contentData?.fields} code={contentData?.fields?.diagram?.code} diagram={contentData?.fields?.diagram?.diagram}  />
    </div>

)
}


export default LayoutTest;