import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Page from "./Page";

import About from "../Element/About";
import LayoutTest from "./DSADesc";
import ProjectDesc from "./ProjectDesc";
import SyncedScrollWrapper from "../Element/DSADetails";

import ProjectDetail from "../Element/ProjectDetail";
import PageNotFound from "../PageNotFound/PageNotFound";
import Cards from "../Element/ProjectCards";
import DSACards from "../Element/DSACards";

import Connectme from "../Element/connect"
import DSADesc from "./DSADesc";

const Body = () => {


  const appRouter = createBrowserRouter ([
   
    {path:'/dsa/:id',element: <LayoutTest/>},
    {path:"*",element:<PageNotFound/>},
    {path: '/projects/:id', element: <ProjectDesc/>}, 
    {path:'/about', element: <About/>},
    {path:'/', element: <About/>},
    {path:'/experience', element: <SyncedScrollWrapper/>},

    {path:'/page', element:<Page/>},
    {path:'/contact', element : <Connectme/>},
    {path: '/dsa/:id', element: <DSADesc/>}, 
    {path:'/dsa',element:<DSACards/>},
    {path:'/projects',element:<Cards/>}
  ]

  )
  
  return (<div >

<div className="h-screen w-screen overflow-hidden text-black">
<div className="h-full w-full">


    <RouterProvider router ={appRouter}/>

   </div>
   </div>
   </div>

)
};

export default Body;
