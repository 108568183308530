// import React, { useEffect, useState } from 'react';
// import { ChevronDown, ChevronUp, Code, PieChart, File } from 'lucide-react';
// import  CodeHighlighter  from './CodeHighliter';
// import MermaidGraph from "./MermaidGraph"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
// const Page = ({ article, code, diagram }) => {
//   const [codeVisible, setCodeVisible] = useState(true);
//   const [diagramVisible, setDiagramVisible] = useState(true);

//   return (

//     <div className="flex flex-col lg:flex-row h-screen  text-gray-200 bg-neutral-800 pl-24  rounded-md p-2 ">
//       {/* Article section - always visible */}
//       <div className="w-full lg:w-1/2  overflow-y-auto rounded-md bg-neutral-900 shadow-md ">
//       <div className=" rounded-md flex justify-start items-center p-1 bg-neutral-700 cursor-pointer w-fill   rounded-t-md">
//               <File size={20} className="mr-2 " />
//               <span>Article</span>
//             </div>
// {/* article.title */}
// <div className='p-12'>
//         <h1 className="text-2xl font-bold mb-4 text-red-500 justify-center flex">{article?.title}</h1>
//         <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//         <div className="prose ">{documentToReactComponents(article?.introduction)}</div>
//         <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-amber-500"/>
//         <div className="prose ">{documentToReactComponents(article?.algoExplaination)}</div>
//         <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-green-500"/>
//         <div className="prose ">{documentToReactComponents(article?.spaceComplexity)}</div>
//         <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-purple-500"/>
//         <div className="prose ">{documentToReactComponents(article?.timeComplexity)}</div>
//         <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//         <div className="prose ">{documentToReactComponents(article?.useCase)}</div>
//       </div>
// </div>
//       {/* Code and Diagram section */}
//       <div className="w-full lg:w-1/2 flex flex-col ">
//         {/* Code section */}
//         <div className="h-2/3 m-1 bg-neutral-900 text-white overflow-hidden rounded-md">
//           <div 
//             className="flex justify-between items-center p-1 bg-neutral-700 cursor-pointer  rounded-t-md"
//             onClick={() => setCodeVisible(!codeVisible)}
//           >
//             <div className="flex items-center  rounded-md">
//               <Code size={20} className="mr-2" />
//               <span>Code</span>
//             </div>
//             {codeVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//           </div>
//           <div className={`p-4 overflow-y-auto transition-all duration-300 ease-in-out   ${codeVisible ? 'h-full' : 'h-0'}`}>
//             <CodeHighlighter code={code?.text} language={code?.language} />
//           </div>
//         </div>

//         {/* Diagram section */}
//         <div className="h-1/3  p-1 overflow-hidden  ">
//           <div 
//             className="flex bg-neutral-700 justify-between items-center p-1 rounded-t-md  cursor-pointer"
//             onClick={() => setDiagramVisible(!diagramVisible)}
//           >
//             <div className="flex   rounded-md items-center">
//               <PieChart size={20} className="mr-2" />
//               <span>Diagram</span>
//             </div>
//             {diagramVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//           </div>
//           <div className={`p-1  rounded-md overflow-y-auto transition-all duration-300 ease-in-out ${diagramVisible ? 'h-full' : 'h-0'}`}>
//           <MermaidGraph graphDefinition={diagram?.graph} />
//           </div>
//         </div>
//       </div>
//     </div>

//   );
// };

// export default Page;



// import React, { useState, useRef, useCallback } from 'react';
// import { ChevronDown, ChevronUp, Code, PieChart, File } from 'lucide-react';
// import CodeHighlighter from './CodeHighliter';
// import MermaidGraph from "./MermaidGraph";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// const Page = ({ article, code, diagram }) => {
//   const [codeVisible, setCodeVisible] = useState(true);
//   const [diagramVisible, setDiagramVisible] = useState(true);
//   const [leftWidth, setLeftWidth] = useState(50);
//   const [topHeight, setTopHeight] = useState(66);
//   const containerRef = useRef(null);
//   const isResizingRef = useRef(false);
//   const currentResizerRef = useRef(null);

//   const handleMouseDown = useCallback((resizer) => {
//     isResizingRef.current = true;
//     currentResizerRef.current = resizer;
//   }, []);

//   const handleMouseUp = useCallback(() => {
//     isResizingRef.current = false;
//     currentResizerRef.current = null;
//   }, []);

//   const handleMouseMove = useCallback((e) => {
//     if (!isResizingRef.current) return;

//     const container = containerRef.current;
//     if (!container) return;

//     if (currentResizerRef.current === 'horizontal') {
//       const containerWidth = container.offsetWidth;
//       const newLeftWidth = (e.clientX / containerWidth) * 100;
//       setLeftWidth(Math.max(20, Math.min(80, newLeftWidth)));
//     } else if (currentResizerRef.current === 'vertical') {
//       const containerHeight = container.offsetHeight;
//       const newTopHeight = ((e.clientY - container.offsetTop) / containerHeight) * 100;
//       setTopHeight(Math.max(20, Math.min(80, newTopHeight)));
//     }
//   }, []);

//   React.useEffect(() => {
//     document.addEventListener('mousemove', handleMouseMove);
//     document.addEventListener('mouseup', handleMouseUp);

//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//       document.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [handleMouseMove, handleMouseUp]);

//   return (
//     <div ref={containerRef} className="flex h-screen text-gray-200 bg-neutral-800 pl-24 rounded-md p-2">
//       {/* Article section - always visible */}
//       <div style={{ width: `${leftWidth}%` }} className="overflow-y-auto rounded-md bg-neutral-900 shadow-md">
//         <div className="rounded-md flex justify-start items-center p-1 bg-neutral-700 cursor-pointer w-fill rounded-t-md">
//           <File size={20} className="mr-2" />
//           <span>Article</span>
//         </div>
//         <div className='p-12 '>
//           <h1 className="text-2xl font-bold mb-4 text-red-500 justify-center flex">{article?.title}</h1>
//           <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//           <div className="prose">{documentToReactComponents(article?.introduction)}</div>
//           <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-amber-500"/>
//           <div className="prose ">{documentToReactComponents(article?.algoExplaination)}</div>
      
//          <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-green-500"/>
//          <div className="prose ">{documentToReactComponents(article?.spaceComplexity)}</div>
//          <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-purple-500"/>
//          <div className="prose ">{documentToReactComponents(article?.timeComplexity)}</div>
//          <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//          <div className="prose ">{documentToReactComponents(article?.useCase)}</div>
//        </div>
  
//       </div>

//       {/* Horizontal Resizer */}
//       <div
//         className="w-1 m-1  bg-neutral-600 cursor-col-resize rounded-xl hover:bg-blue-500 "
//         onMouseDown={() => handleMouseDown('horizontal')}
//       />

//       {/* Code and Diagram section */}
//       <div style={{ width: `${100 - leftWidth}%` }} className="flex flex-col">
//         {/* Code section */}
//         <div style={{ height: `${topHeight}%` }} className="m-1 bg-neutral-900 text-gray-100 font-semibold overflow-hidden rounded-md">
//           <div
//             className="flex justify-between items-center p-1 bg-neutral-700 cursor-pointer rounded-t-md"
//             onClick={() => setCodeVisible(!codeVisible)}
//           >
//             <div className="flex items-center rounded-md">
//               <Code size={20} className="mr-2" />
//               <span>Code</span>
//             </div>
//             {codeVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//           </div>
//           <div className={`p-4 overflow-y-auto transition-all duration-300 ease-in-out ${codeVisible ? 'h-full' : 'h-0'}`}>
//             <CodeHighlighter code={code?.text} language={code?.language} />
//           </div>
//         </div>

//         {/* Vertical Resizer */}
//         <div
//           className="h-1 m-1  bg-neutral-600 cursor-row-resize rounded-xl hover:bg-blue-500"
//           onMouseDown={() => handleMouseDown('vertical')}
//         />

//         {/* Diagram section */}
//         <div style={{ height: `${100 - topHeight}%` }} className="p-1 overflow-hidden">
//           <div
//             className="flex bg-neutral-700 justify-between items-center p-1 rounded-t-md cursor-pointer"
//             onClick={() => setDiagramVisible(!diagramVisible)}
//           >
//             <div className="flex rounded-md items-center">
//               <PieChart size={20} className="mr-2" />
//               <span>Diagram</span>
//             </div>
//             {diagramVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//           </div>
//           <div className={`p-1 rounded-md overflow-y-auto transition-all duration-300 ease-in-out ${diagramVisible ? 'h-full' : 'h-0'}`}>
//             <MermaidGraph graphDefinition={diagram?.graph} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Page;

// import React, { useState, useRef, useCallback, useEffect } from 'react';
// import { ChevronDown, ChevronUp, Code, PieChart, File } from 'lucide-react';
// import CodeHighlighter from './CodeHighliter';
// import MermaidGraph from "./MermaidGraph";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// const Page = ({ article, code, diagram }) => {
//   const [codeVisible, setCodeVisible] = useState(true);
//   const [diagramVisible, setDiagramVisible] = useState(true);
//   const [leftWidth, setLeftWidth] = useState(50);
//   const [topHeight, setTopHeight] = useState(66);
//   const [activeTab, setActiveTab] = useState('article');
//   const containerRef = useRef(null);
//   const isResizingRef = useRef(false);
//   const currentResizerRef = useRef(null);

//   const handleMouseDown = useCallback((resizer) => {
//     isResizingRef.current = true;
//     currentResizerRef.current = resizer;
//   }, []);

//   const handleMouseUp = useCallback(() => {
//     isResizingRef.current = false;
//     currentResizerRef.current = null;
//   }, []);

//   const handleMouseMove = useCallback((e) => {
//     if (!isResizingRef.current) return;

//     const container = containerRef.current;
//     if (!container) return;

//     if (currentResizerRef.current === 'horizontal') {
//       const containerWidth = container.offsetWidth;
//       const newLeftWidth = (e.clientX / containerWidth) * 100;
//       setLeftWidth(Math.max(20, Math.min(80, newLeftWidth)));
//     } else if (currentResizerRef.current === 'vertical') {
//       const containerHeight = container.offsetHeight;
//       const newTopHeight = ((e.clientY - container.offsetTop) / containerHeight) * 100;
//       setTopHeight(Math.max(20, Math.min(80, newTopHeight)));
//     }
//   }, []);

//   useEffect(() => {
//     document.addEventListener('mousemove', handleMouseMove);
//     document.addEventListener('mouseup', handleMouseUp);

//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//       document.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [handleMouseMove, handleMouseUp]);

//   const TabButton = ({ tab, icon: Icon }) => (
//     <button
//       className={`flex-1 py-2 px-4 flex items-center justify-center ${activeTab === tab ? 'bg-neutral-700 text-white' : 'bg-neutral-800 text-gray-400'}`}
//       onClick={() => setActiveTab(tab)}
//     >
//       <Icon size={20} className="mr-2" />
//       <span className="capitalize">{tab}</span>
//     </button>
//   );

//   return (
//     <div ref={containerRef} className="flex flex-col lg:flex-row h-screen text-gray-200 bg-neutral-800 lg:pl-24 rounded-md p-2">
//       {/* Mobile Tabs */}
//       <div className="lg:hidden flex border-b border-neutral-700">
//         <TabButton tab="article" icon={File} />
//         <TabButton tab="code" icon={Code} />
//         <TabButton tab="diagram" icon={PieChart} />
//       </div>

//       {/* Article section */}
//       <div className={`w-full lg:w-${leftWidth}% overflow-y-auto rounded-md bg-neutral-900 shadow-md ${activeTab === 'article' ? 'block' : 'hidden lg:block'}`}>
//         <div className="rounded-md flex justify-start items-center p-1 bg-neutral-700 cursor-pointer w-full rounded-t-md">
//           <File size={20} className="mr-2" />
//           <span>Article</span>
//         </div>
//         <div className='p-12 '>
//           <h1 className="text-2xl font-bold mb-4 text-red-500 justify-center flex">{article?.title}</h1>
//           <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//           <div className="prose">{documentToReactComponents(article?.introduction)}</div>
//           <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-amber-500"/>
//           <div className="prose ">{documentToReactComponents(article?.algoExplaination)}</div>
      
//          <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-green-500"/>
//          <div className="prose ">{documentToReactComponents(article?.spaceComplexity)}</div>
//          <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-purple-500"/>
//          <div className="prose ">{documentToReactComponents(article?.timeComplexity)}</div>
//          <hr class="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//          <div className="prose ">{documentToReactComponents(article?.useCase)}</div>
//        </div>
  
//       </div>
//       {/* Horizontal Resizer (hidden on mobile) */}
//       <div
//         className="hidden lg:block w-1 bg-gray-600 cursor-col-resize"
//         onMouseDown={() => handleMouseDown('horizontal')}
//       />

//       {/* Code and Diagram section */}
//       <div className={`w-full lg:w-${100 - leftWidth}% flex flex-col`}>
//         {/* Code section */}
//         <div className={`h-full lg:h-${topHeight}% m-1 bg-neutral-900 text-white overflow-hidden rounded-md ${activeTab === 'code' ? 'block' : 'hidden lg:block'}`}>
//           <div
//             className="flex justify-between items-center p-1 bg-neutral-700 cursor-pointer rounded-t-md"
//             onClick={() => setCodeVisible(!codeVisible)}
//           >
//             <div className="flex items-center rounded-md">
//               <Code size={20} className="mr-2" />
//               <span>Code</span>
//             </div>
//             {codeVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//           </div>
//           <div className={`p-4 overflow-y-auto transition-all duration-300 ease-in-out ${codeVisible ? 'h-full' : 'h-0'}`}>
//             <CodeHighlighter code={code?.text} language={code?.language} />
//           </div>
//         </div>

//         {/* Vertical Resizer (hidden on mobile) */}
//         <div
//           className="hidden lg:block h-1 bg-gray-600 cursor-row-resize"
//           onMouseDown={() => handleMouseDown('vertical')}
//         />

//         {/* Diagram section */}
//         <div className={`h-full lg:h-${100 - topHeight}% p-1 overflow-hidden ${activeTab === 'diagram' ? 'block' : 'hidden lg:block'}`}>
//           <div
//             className="flex bg-neutral-700 justify-between items-center p-1 rounded-t-md cursor-pointer"
//             onClick={() => setDiagramVisible(!diagramVisible)}
//           >
//             <div className="flex rounded-md items-center">
//               <PieChart size={20} className="mr-2" />
//               <span>Diagram</span>
//             </div>
//             {diagramVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//           </div>
//           <div className={`p-1 rounded-md overflow-y-auto transition-all duration-300 ease-in-out ${diagramVisible ? 'h-full' : 'h-0'}`}>
//             <MermaidGraph graphDefinition={diagram?.graph} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Page;




// import React, { useState, useRef, useCallback, useEffect } from 'react';
// import { ChevronDown, ChevronUp, Code, PieChart, File } from 'lucide-react';
// import CodeHighlighter from './CodeHighliter';
// import MermaidGraph from "./MermaidGraph";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// const Page = ({ article, code, diagram }) => {
//   const [codeVisible, setCodeVisible] = useState(true);
//   const [diagramVisible, setDiagramVisible] = useState(true);
//   const [leftWidth, setLeftWidth] = useState(50);
//   const [topHeight, setTopHeight] = useState(66);
//   const [activeTab, setActiveTab] = useState('article');
//   const containerRef = useRef(null);
//   const isResizingRef = useRef(false);
//   const currentResizerRef = useRef(null);

//   const handleMouseDown = useCallback((resizer) => {
//     isResizingRef.current = true;
//     currentResizerRef.current = resizer;
//   }, []);

//   const handleMouseUp = useCallback(() => {
//     isResizingRef.current = false;
//     currentResizerRef.current = null;
//   }, []);

//   const handleMouseMove = useCallback((e) => {
//     if (!isResizingRef.current) return;

//     const container = containerRef.current;
//     if (!container) return;

//     if (currentResizerRef.current === 'horizontal') {
//       const containerWidth = container.offsetWidth;
//       const newLeftWidth = (e.clientX / containerWidth) * 100;
//       setLeftWidth(Math.max(20, Math.min(80, newLeftWidth)));
//     } else if (currentResizerRef.current === 'vertical') {
//       const containerHeight = container.offsetHeight;
//       const rightSectionHeight = container.querySelector('.right-section').offsetHeight;
//       const newTopHeight = ((e.clientY - container.offsetTop) / rightSectionHeight) * 100;
//       setTopHeight(Math.max(20, Math.min(80, newTopHeight)));
//     }
//   }, []);

//   useEffect(() => {
//     document.addEventListener('mousemove', handleMouseMove);
//     document.addEventListener('mouseup', handleMouseUp);

//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//       document.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [handleMouseMove, handleMouseUp]);

//   const TabButton = ({ tab, icon: Icon }) => (
//     <button
//       className={`flex-1 py-2 px-4 flex items-center  justify-center ${activeTab === tab ? 'bg-neutral-700 text-white' : 'bg-neutral-800 text-gray-400'}`}
//       onClick={() => setActiveTab(tab)}
//     >
//       <Icon size={20} className="mr-2" />
//       <span className="capitalize">{tab}</span>
//     </button>
//   );

//   return (
//     <div ref={containerRef} className="flex flex-col lg:flex-row h-screen text-gray-200 bg-neutral-800 lg:pl-24 rounded-md p-2">
//       {/* Mobile Tabs */}
//       <div className="lg:hidden flex border-b border-neutral-700">
//         <TabButton tab="article" icon={File} />
//         <TabButton tab="code" icon={Code} />
//         <TabButton tab="diagram" icon={PieChart} />
//       </div>

//       {/* Article section */}
//       <div 
//         style={{ width: `${leftWidth}%` }}
//         className={`w-full lg:w-auto overflow-y-auto rounded-md bg-neutral-900 shadow-md  ${activeTab === 'article' ? 'block' : 'hidden lg:block'}`}
//       >
//         <div className="rounded-md flex justify-start items-center p-1 bg-neutral-700 cursor-pointer w-full rounded-t-md ">
//           <File size={20} className="mr-2" />
//           <span>Article</span>
//         </div>
//         <div className='p-12'>
//           <h1 className="text-2xl font-bold mb-4 text-red-500 justify-center flex">{article?.title}</h1>
//           <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//           <div className="prose">{documentToReactComponents(article?.introduction)}</div>
//           <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-amber-500"/>
//           <div className="prose">{documentToReactComponents(article?.algoExplaination)}</div>
//           <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-green-500"/>
//           <div className="prose">{documentToReactComponents(article?.spaceComplexity)}</div>
//           <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-purple-500"/>
//           <div className="prose">{documentToReactComponents(article?.timeComplexity)}</div>
//           <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//           <div className="prose">{documentToReactComponents(article?.useCase)}</div>
//         </div>
//       </div>

//       {/* Horizontal Resizer (hidden on mobile) */}
//       <div
//         className="hidden lg:block w-1 bg-gray-600 cursor-col-resize"
//         onMouseDown={() => handleMouseDown('horizontal')}
//       />

//       {/* Code and Diagram section */}
//       <div 
//         style={{ width: `${100 - leftWidth}%` }}
//         className="right-section w-full lg:w-auto flex flex-col"
//       >
//         {/* Code section */}
//         <div 
//           style={{ height: `${topHeight}%` }}
//           className={`h-full lg:h-auto m-1 bg-neutral-900 text-white overflow-hidden rounded-md ${activeTab === 'code' ? 'block' : 'hidden lg:block'}`}
//         >
//           <div
//             className="flex justify-between items-center p-1 bg-neutral-700 cursor-pointer rounded-t-md"
//             onClick={() => setCodeVisible(!codeVisible)}
//           >
//             <div className="flex items-center rounded-md">
//               <Code size={20} className="mr-2" />
//               <span>Code</span>
//             </div>
//             {codeVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//           </div>
//           <div className={`p-4 overflow-y-auto transition-all duration-300 ease-in-out ${codeVisible ? 'h-full' : 'h-0'}`}>
//             <CodeHighlighter code={code?.text} language={code?.language} />
//           </div>
//         </div>

//         {/* Vertical Resizer (hidden on mobile) */}
//         <div
//           className="hidden lg:block h-1 bg-gray-600 cursor-row-resize"
//           onMouseDown={() => handleMouseDown('vertical')}
//         />

//         {/* Diagram section */}
//         <div 
//           style={{ height: `${100 - topHeight}%` }}
//           className={`h-full lg:h-auto p-1 overflow-hidden ${activeTab === 'diagram' ? 'block' : 'hidden lg:block'}`}
//         >
//           <div
//             className="flex bg-neutral-700 justify-between items-center p-1 rounded-t-md cursor-pointer"
//             onClick={() => setDiagramVisible(!diagramVisible)}
//           >
//             <div className="flex rounded-md items-center">
//               <PieChart size={20} className="mr-2" />
//               <span>Diagram</span>
//             </div>
//             {diagramVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//           </div>
//           <div className={`p-1 rounded-md overflow-y-auto transition-all duration-300 ease-in-out ${diagramVisible ? 'h-full' : 'h-0'}`}>
//             <MermaidGraph graphDefinition={diagram?.graph} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Page;

// =====================
// import React, { useState, useRef, useCallback, useEffect } from 'react';
// import { ChevronDown, ChevronUp, Code, PieChart, File } from 'lucide-react';
// import CodeHighlighter from './CodeHighliter';
// import MermaidGraph from "./MermaidGraph";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// const Page = ({ article, code, diagram }) => {
//   const [codeVisible, setCodeVisible] = useState(true);
//   const [diagramVisible, setDiagramVisible] = useState(true);
//   const [leftWidth, setLeftWidth] = useState(50);
//   const [topHeight, setTopHeight] = useState(66);
//   const [activeTab, setActiveTab] = useState('article');
//   const containerRef = useRef(null);
//   const isResizingRef = useRef(false);
//   const currentResizerRef = useRef(null);

//   const handleMouseDown = useCallback((resizer) => {
//     isResizingRef.current = true;
//     currentResizerRef.current = resizer;
//   }, []);

//   const handleMouseUp = useCallback(() => {
//     isResizingRef.current = false;
//     currentResizerRef.current = null;
//   }, []);

//   const handleMouseMove = useCallback((e) => {
//     if (!isResizingRef.current) return;

//     const container = containerRef.current;
//     if (!container) return;

//     if (currentResizerRef.current === 'horizontal') {
//       const containerWidth = container.offsetWidth;
//       const newLeftWidth = (e.clientX / containerWidth) * 100;
//       setLeftWidth(Math.max(20, Math.min(80, newLeftWidth)));
//     } else if (currentResizerRef.current === 'vertical') {
//       const containerHeight = container.offsetHeight;
//       const rightSectionHeight = container.querySelector('.right-section').offsetHeight;
//       const newTopHeight = ((e.clientY - container.offsetTop) / rightSectionHeight) * 100;
//       setTopHeight(Math.max(20, Math.min(80, newTopHeight)));
//     }
//   }, []);

//   useEffect(() => {
//     document.addEventListener('mousemove', handleMouseMove);
//     document.addEventListener('mouseup', handleMouseUp);

//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//       document.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [handleMouseMove, handleMouseUp]);

//   const TabButton = ({ tab, icon: Icon }) => (
//     <button
//       className={`flex-1 py-2 px-4 flex items-center justify-center ${activeTab === tab ? 'bg-neutral-700 text-white' : 'bg-neutral-800 text-gray-400'}`}
//       onClick={() => setActiveTab(tab)}
//     >
//       <Icon size={20} className="mr-2" />
//       <span className="capitalize">{tab}</span>
//     </button>
//   );

//   return (
//     <div ref={containerRef} className="flex flex-col lg:pl-20    lg:flex-row h-screen w-full text-gray-200 bg-neutral-800 overflow-hidden">
//       {/* Mobile Tabs */}
//       <div className="lg:hidden flex border-b border-neutral-700">
//         <TabButton tab="article" icon={File} />
//         <TabButton tab="code" icon={Code} />
//         <TabButton tab="diagram" icon={PieChart} />
//       </div>

//       {/* Content Container */}
//       <div className="flex flex-col lg:flex-row flex-grow ">
//         {/* Article section */}
//         <div 
//           style={{ width: activeTab === 'article' ? '100%' : `${leftWidth}%` }}
//           className={`h-full overflow-y-auto bg-neutral-900 shadow-md ${activeTab === 'article' ? 'block' : 'hidden lg:block'}`}
//         >
//           <div className="sticky top-0 z-10 flex justify-start items-center p-1 bg-neutral-700 w-full">
//             <File size={20} className="mr-2" />
//             <span>Article</span>
//           </div>
//           <div className='p-4 lg:p-12 '>
//             <h1 className="text-2xl font-bold mb-4 text-red-500 justify-center flex">{article?.title}</h1>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//             <div className="prose">{documentToReactComponents(article?.introduction)}</div>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-amber-500"/>
//             <div className="prose">{documentToReactComponents(article?.algoExplaination)}</div>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-green-500"/>
//             <div className="prose">{documentToReactComponents(article?.spaceComplexity)}</div>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-purple-500"/>
//             <div className="prose">{documentToReactComponents(article?.timeComplexity)}</div>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//             <div className="prose">{documentToReactComponents(article?.useCase)}</div>
//           </div>
//         </div>

//         {/* Horizontal Resizer (hidden on mobile) */}
//         <div
//           className="hidden lg:block w-1 bg-gray-600 cursor-col-resize"
//           onMouseDown={() => handleMouseDown('horizontal')}
//         />

//         {/* Code and Diagram section */}
//         <div 
//           style={{ width: activeTab !== 'article' ? '100%' : `${100 - leftWidth}%` }}
//           className="right-section flex flex-col h-full"
//         >
//           {/* Code section */}
//           <div 
//             style={{ height: activeTab === 'code' ? '100%' : `${topHeight}%` }}
//             className={`bg-neutral-900 text-white overflow-hidden ${activeTab === 'code' ? 'block' : 'hidden lg:block'}`}
//           >
//             <div
//               className="sticky top-0 z-10 flex justify-between items-center p-1 bg-neutral-700 cursor-pointer"
//               onClick={() => setCodeVisible(!codeVisible)}
//             >
//               <div className="flex items-center">
//                 <Code size={20} className="mr-2" />
//                 <span>Code</span>
//               </div>
//               {codeVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//             </div>
//             <div className={`p-4 overflow-y-auto h-full ${codeVisible ? 'block' : 'hidden'}`}>
//               <CodeHighlighter code={code?.text} language={code?.language} />
//             </div>
//           </div>

//           {/* Vertical Resizer (hidden on mobile) */}
//           <div
//             className="hidden lg:block h-1 bg-gray-600 cursor-row-resize"
//             onMouseDown={() => handleMouseDown('vertical')}
//           />

//           {/* Diagram section */}
//           <div 
//             style={{ height: activeTab === 'diagram' ? '100%' : `${100 - topHeight}%` }}
//             className={`bg-neutral-900 overflow-hidden ${activeTab === 'diagram' ? 'block' : 'hidden lg:block'}`}
//           >
//             <div
//               className="sticky top-0 z-10 flex justify-between items-center p-1 bg-neutral-700 cursor-pointer"
//               onClick={() => setDiagramVisible(!diagramVisible)}
//             >
//               <div className="flex items-center">
//                 <PieChart size={20} className="mr-2" />
//                 <span>Diagram</span>
//               </div>
//               {diagramVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//             </div>
//             <div className={`p-4 overflow-y-auto h-full ${diagramVisible ? 'block' : 'hidden'}`}>
//               <MermaidGraph graphDefinition={diagram?.graph} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Page;
// import React, { useState, useRef, useCallback, useEffect } from 'react';
// import { ChevronDown, ChevronUp, Code, PieChart, File } from 'lucide-react';
// import CodeHighlighter from './CodeHighliter';
// import MermaidGraph from "./MermaidGraph";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// const Page = ({ article, code, diagram }) => {
//   const [codeVisible, setCodeVisible] = useState(true);
//   const [diagramVisible, setDiagramVisible] = useState(true);
//   const [leftWidth, setLeftWidth] = useState(50);
//   const [topHeight, setTopHeight] = useState(66);
//   const [activeTab, setActiveTab] = useState('article');
//   const containerRef = useRef(null);
//   const isResizingRef = useRef(false);
//   const currentResizerRef = useRef(null);

//   const handleMouseDown = useCallback((resizer) => {
//     isResizingRef.current = true;
//     currentResizerRef.current = resizer;
//   }, []);

//   const handleMouseUp = useCallback(() => {
//     isResizingRef.current = false;
//     currentResizerRef.current = null;
//   }, []);

//   const handleMouseMove = useCallback((e) => {
//     if (!isResizingRef.current) return;

//     const container = containerRef.current;
//     if (!container) return;

//     if (currentResizerRef.current === 'horizontal') {
//       const containerWidth = container.offsetWidth;
//       const newLeftWidth = (e.clientX / containerWidth) * 100;
//       setLeftWidth(Math.max(20, Math.min(80, newLeftWidth)));
//     } else if (currentResizerRef.current === 'vertical') {
//       const containerHeight = container.offsetHeight;
//       const rightSectionHeight = container.querySelector('.right-section').offsetHeight;
//       const newTopHeight = ((e.clientY - container.offsetTop) / rightSectionHeight) * 100;
//       setTopHeight(Math.max(20, Math.min(80, newTopHeight)));
//     }
//   }, []);

//   useEffect(() => {
//     document.addEventListener('mousemove', handleMouseMove);
//     document.addEventListener('mouseup', handleMouseUp);

//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//       document.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [handleMouseMove, handleMouseUp]);

//   const TabButton = ({ tab, icon: Icon }) => (
//     <button
//       className={`flex-1 py-2 px-4 flex items-center justify-center ${activeTab === tab ? 'bg-neutral-700 text-white' : 'bg-neutral-800 text-gray-400'}`}
//       onClick={() => setActiveTab(tab)}
//     >
//       <Icon size={20} className="mr-2" />
//       <span className="capitalize">{tab}</span>
//     </button>
//   );

//   return (
//     <div ref={containerRef} className="flex flex-col lg:pl-20 lg:flex-row h-screen w-full text-gray-200 bg-neutral-800 overflow-hidden">
//       {/* Mobile Tabs */}
//       <div className="lg:hidden flex border-b border-neutral-700">
//         <TabButton tab="article" icon={File} />
//         <TabButton tab="code" icon={Code} />
//         <TabButton tab="diagram" icon={PieChart} />
//       </div>

//       {/* Content Container */}
//       <div className="flex flex-col lg:flex-row flex-grow">
//         {/* Article section */}
//         <div 
//           style={{ width: activeTab === 'article' || window.innerWidth < 1024 ? '100%' : `${leftWidth}%` }}
//           className={`h-full overflow-y-auto bg-neutral-900 shadow-md ${activeTab === 'article' ? 'block' : 'hidden lg:block'}`}
//         >
//           <div className="sticky top-0 z-10 flex justify-start items-center p-1 bg-neutral-700 w-full">
//             <File size={20} className="mr-2" />
//             <span>Article</span>
//           </div>
//           <div className='p-4 lg:p-12'>
//             <h1 className="text-2xl font-bold mb-4 text-red-500 justify-center flex">{article?.title}</h1>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//             <div className="prose">{documentToReactComponents(article?.introduction)}</div>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-amber-500"/>
//             <div className="prose">{documentToReactComponents(article?.algoExplaination)}</div>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-green-500"/>
//             <div className="prose">{documentToReactComponents(article?.spaceComplexity)}</div>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-purple-500"/>
//             <div className="prose">{documentToReactComponents(article?.timeComplexity)}</div>
//             <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
//             <div className="prose">{documentToReactComponents(article?.useCase)}</div>
//           </div>
//         </div>

//         {/* Horizontal Resizer (hidden on mobile) */}
//         <div
//           className="hidden lg:block w-1 bg-gray-600 cursor-col-resize"
//           onMouseDown={() => handleMouseDown('horizontal')}
//         />

//         {/* Code and Diagram section */}
//         <div 
//           style={{ width: activeTab !== 'article' || window.innerWidth < 1024 ? '100%' : `${100 - leftWidth}%` }}
//           className="right-section flex flex-col h-full"
//         >
//           {/* Code section */}
//           <div 
//             style={{ height: activeTab === 'code' || window.innerWidth < 1024 ? '100%' : `${topHeight}%` }}
//             className={`bg-neutral-900 text-white overflow-hidden ${activeTab === 'code' ? 'block' : 'hidden lg:block'}`}
//           >
//             <div
//               className="sticky top-0 z-10 flex justify-between items-center p-1 bg-neutral-700 cursor-pointer"
//               onClick={() => setCodeVisible(!codeVisible)}
//             >
//               <div className="flex items-center">
//                 <Code size={20} className="mr-2" />
//                 <span>Code</span>
//               </div>
//               {codeVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//             </div>
//             <div className={`p-4 overflow-y-auto h-full ${codeVisible ? 'block' : 'hidden'}`}>
//               <CodeHighlighter code={code?.text} language={code?.language} />
//             </div>
//           </div>

//           {/* Vertical Resizer (hidden on mobile) */}
//           <div
//             className="hidden lg:block h-1 bg-gray-600 cursor-row-resize"
//             onMouseDown={() => handleMouseDown('vertical')}
//           />

//           {/* Diagram section */}
//           <div 
//             style={{ height: activeTab === 'diagram' || window.innerWidth < 1024 ? '100%' : `${100 - topHeight}%` }}
//             className={`bg-neutral-900 overflow-hidden ${activeTab === 'diagram' ? 'block' : 'hidden lg:block'}`}
//           >
//             <div
//               className="sticky top-0 z-10 flex justify-between items-center p-1 bg-neutral-700 cursor-pointer"
//               onClick={() => setDiagramVisible(!diagramVisible)}
//             >
//               <div className="flex items-center">
//                 <PieChart size={20} className="mr-2" />
//                 <span>Diagram</span>
//               </div>
//               {diagramVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//             </div>
//             <div className={`p-4 overflow-y-auto h-full ${diagramVisible ? 'block' : 'hidden'}`}>
//               <MermaidGraph graphDefinition={diagram?.graph} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Page;

import React, { useState, useRef, useCallback, useEffect } from 'react';
import { ChevronDown, ChevronUp, Code, PieChart, File } from 'lucide-react';
import CodeHighlighter from './CodeHighliter';
import MermaidGraph from "./MermaidGraph";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Page = ({ article, code, diagram }) => {
  const [codeVisible, setCodeVisible] = useState(true);
  const [diagramVisible, setDiagramVisible] = useState(true);
  const [leftWidth, setLeftWidth] = useState(50);
  const [topHeight, setTopHeight] = useState(66);
  const [activeTab, setActiveTab] = useState('article');
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const isResizingRef = useRef(false);
  const currentResizerRef = useRef(null);

  const handleMouseDown = useCallback((resizer) => {
    isResizingRef.current = true;
    currentResizerRef.current = resizer;
  }, []);

  const handleMouseUp = useCallback(() => {
    isResizingRef.current = false;
    currentResizerRef.current = null;
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!isResizingRef.current) return;

    const container = containerRef.current;
    if (!container) return;

    if (currentResizerRef.current === 'horizontal') {
      const containerRect = container.getBoundingClientRect();
      const newLeftWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;
      setLeftWidth(Math.max(20, Math.min(80, newLeftWidth)));
    } else if (currentResizerRef.current === 'vertical') {
      const rightSection = container.querySelector('.right-section');
      const rightSectionRect = rightSection.getBoundingClientRect();
      const newTopHeight = ((e.clientY - rightSectionRect.top) / rightSectionRect.height) * 100;
      setTopHeight(Math.max(20, Math.min(80, newTopHeight)));
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const TabButton = ({ tab, icon: Icon }) => (
    <button
      className={`flex-1 py-2 px-4 flex items-center justify-center ${activeTab === tab ? 'bg-neutral-700 text-white' : 'bg-neutral-800 text-gray-400'}`}
      onClick={() => setActiveTab(tab)}
    >
      <Icon size={20} className="mr-2" />
      <span className="capitalize">{tab}</span>
    </button>
  );

  return (
    <div ref={containerRef} className="flex flex-col lg:pl-20 lg:flex-row h-screen w-full text-gray-200 bg-neutral-800 overflow-hidden  ">
      {/* Mobile Tabs */}
      <div className="lg:hidden flex border-b border-neutral-700 ">
        <TabButton tab="article" icon={File} />
        <TabButton tab="code" icon={Code} />
        <TabButton tab="diagram" icon={PieChart} />
      </div>

      {/* Content Container */}
      <div className="flex flex-col lg:flex-row flex-grow">
        {/* Article section */}
        <div 
         style={{
          width: isMobile ? '100%' : `${leftWidth}%`,
          height: isMobile ? (activeTab === 'article' ? '100%' : '0') : '100%',
          overflowY: 'auto',
          maxHeight: '100vh'
        }}
          className={`overflow-y-auto bg-neutral-900 shadow-md custom-red-scrollbar ${isMobile && activeTab !== 'article' ? 'hidden' : 'block'}`}
        >
          <div className="sticky top-0 z-10 flex justify-start items-center p-1 bg-beige-dark w-full  rounded-bl-xl">
            <File size={20} className="mr-2" />
            <span>Article</span>
          </div>
          <div className='p-4 lg:p-12'>
            <h1 className="text-2xl font-bold mb-4 text-blue-500 justify-center flex  rounded-md">{article?.title}</h1>
            <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-red-500"/>
            <div className="prose bg-beige rounded-md p-5">{documentToReactComponents(article?.introduction)}</div>
            <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-amber-500"/>
            <div className="prose bg-beige rounded-md p-5">{documentToReactComponents(article?.algoExplaination)}</div>
            <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-green-500"/>
            <div className="prose bg-beige rounded-md p-5">{documentToReactComponents(article?.spaceComplexity)}</div>
            <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-purple-500"/>
            <div className="prose bg-beige rounded-md p-5">{documentToReactComponents(article?.timeComplexity)}</div>
            <hr className="h-px my-8 bg-amber-200 border-0 dark:bg-blue-500"/>
            <div className="prose bg-beige rounded-md p-5">{documentToReactComponents(article?.useCase)}</div>
          </div>
        </div>

        {/* Horizontal Resizer (hidden on mobile) */}
        <div
          className="hidden lg:block w-1 bg-neutral-900 cursor-col-resize"
          onMouseDown={() => handleMouseDown('horizontal')}
        />

        {/* Code and Diagram section */}
        <div 
          style={{ width: isMobile ? '100%' : `${100 - leftWidth}%`, height: isMobile ? (activeTab !== 'article' ? '100%' : '0') : '100%' ,
          overflowY: 'auto',
          maxHeight: '100vh'}}
          className={`right-section flex flex-col c ${isMobile && activeTab === 'article' ? 'hidden' : 'block'}`}
        >
          {/* Code section */}
          <div 
            style={{ height: isMobile ? (activeTab === 'code' ? '100%' : '0') : `${topHeight}%` }}
            className={`bg-neutral-900 text-gray-200 overflow-hidden   ${isMobile && activeTab !== 'code' ? 'hidden' : 'block'}`}
          >
            <div
              className="sticky top-0 z-10 flex justify-between items-center p-1 rounded-bl-xl  bg-beige-dark cursor-pointer "
              onClick={() => setCodeVisible(!codeVisible)}
            >
              <div className="flex items-center ">
                <Code size={20} className="mr-2 " />
                <span>Code</span>
              </div>
              {codeVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
            <div className={`p-4 overflow-y-auto h-full custom-red-scrollbar ${codeVisible ? 'block' : 'hidden'}`}>
              <CodeHighlighter code={code?.text} language={code?.language} />
            </div>
          </div>

          {/* Vertical Resizer (hidden on mobile) */}
          <div
            className="hidden lg:block h-1 bg-neutral-900 cursor-row-resize"
            onMouseDown={() => handleMouseDown('vertical')}
          />

          {/* Diagram section */}
          <div 
            style={{ height: isMobile ? (activeTab === 'diagram' ? '100%' : '0') : `${100 - topHeight}%` }}
            className={`bg-neutral-900 overflow-hidden ${isMobile && activeTab !== 'diagram' ? 'hidden' : 'block'}`}
          >
            <div
              className="sticky top-0 z-10 flex justify-between items-center p-1 bg-beige-dark cursor-pointer rounded-l-xl"
              onClick={() => setDiagramVisible(!diagramVisible)}
            >
              <div className="flex items-center">
                <PieChart size={20} className="mr-2" />
                <span>Diagram</span>
              </div>
              {diagramVisible ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
            <div className={`p-4 overflow-y-auto h-full ${diagramVisible ? 'block' : 'hidden'}`}>
              <MermaidGraph graphDefinition={diagram?.graph} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;