import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, Home, RefreshCw } from 'lucide-react';

const quantumConcepts = [
  {
    title: "Schrödinger's Page",
    description: "This page exists in a superposition of states. Until you clicked, it was both here and missing. Looks like you collapsed the wavefunction to 404 – Not Found."
  },
  {
    title: "Uncertainty Principle",
    description: "The more you try to find this page, the more uncertain its location becomes. What you're looking for is beyond the limits of observation – 404 Not Found."
  },
  {
    title: "Quantum Entanglement",
    description: "This page was once entangled with another, but now it's lost in the quantum realm. 404 – Not Found."
  },
  {
    title: "Wave-Particle Duality",
    description: "This page couldn't decide whether to be a wave or a particle, so it ended up as neither – 404 Not Found."
  },
  {
    title: "Quantum Tunneling",
    description: "The information you're seeking tried to quantum tunnel through, but it got stuck at 404 – Not Found."
  },
  {
    title: "Multiverse Glitch",
    description: "In this universe, the page you're looking for is missing. Maybe it exists in another dimension – 404 Not Found."
  },
  {
    title: "Financial Glitch",
    description: "This is – 404 Not Found, but for everything else, there's Mastercard"
  }
];

const QuantumPageNotFound = () => {
  const [concept, setConcept] = useState(quantumConcepts[0]);

  useEffect(() => {
    setConcept(quantumConcepts[Math.floor(Math.random() * quantumConcepts.length)]);
  }, []);

  const refreshConcept = () => {
    setConcept(quantumConcepts[Math.floor(Math.random() * quantumConcepts.length)]);
  };

  return (
    <motion.div 
      className="w-screen h-screen flex flex-col justify-center items-center  bg-neutral-800 text-gray-200 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className="max-w-2xl w-full bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-8 mb-8"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <motion.div 
          className="flex items-center justify-center mb-6"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        >
          <AlertTriangle className="text-amber-400 mr-3" size={48} />
          <h1 className="text-5xl text-amber-400 font-bold">404</h1>
        </motion.div>

        <motion.h2 
          className="text-2xl md:text-3xl text-center font-medium mb-4"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {concept.title}
        </motion.h2>
        
        <motion.p 
          className="text-center text-lg font-mono mb-8"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          {concept.description}
        </motion.p>

        <div className="flex justify-center space-x-4">
          <motion.button
            className="flex items-center px-4 py-2 bg-neutral-800 text-white rounded-md hover:bg-amber-700 transition duration-300 ease-in-out"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.location.href="/"}
          >
            <Home className="mr-2" size={20} />
            Home
          </motion.button>
          <motion.button
            className="flex items-center px-4 py-2 bg-neutral-800 text-white rounded-md hover:bg-amber-700 transition duration-300 ease-in-out"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={refreshConcept}
          >
            <RefreshCw className="mr-2" size={20} />
            New Concept
          </motion.button>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default QuantumPageNotFound;