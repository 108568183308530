import React,{useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import Page from "./Page";
import { useDataStructureAPICallById } from "../RQuery";
const  DSADesc=() =>{
  const { id } = useParams();
    const { data: apiResponse, isLoading, isError } = useDataStructureAPICallById(id);
    const [contentData, setContentData] = useState([]);
    useEffect(() => {
        if (apiResponse?.data) {
          setContentData(apiResponse?.data);
          console.log("testData", contentData?.fields?.title)
        } else {
          console.error("Received data is not in the expected format:", apiResponse);
          setContentData([]);
        }
      }, [apiResponse]);



      if (isLoading ) {

        return ( <div ><div className="animate-pulse flex flex-col h-screen bg-gradient-to-t from-neutral-900 to-neutral-900">
          {/* Navbar */}
          <div className="bg-gradient-to-b from-neutral-900 to-neutral-800 p-4 rounded-md shadow-md">
            <div className="h-8 bg-neutral-700 rounded w-3/4 mb-4"></div>
          </div>
          
          {/* Main content */}
          <div className="flex flex-1 overflow-hidden pl-16 pt-1">
            {/* Left sidebar */}
            <div className="w-1/6 mr-2">
              <div className="h-full bg-neutral-800 rounded-md shadow-md p-4">
                <div className="h-4 bg-neutral-700 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-neutral-700 rounded w-1/2 mb-4"></div>
                <div className="h-4 bg-neutral-700 rounded w-5/6 mb-4"></div>
              </div>
            </div>
            
            {/* Main content area */}
            <div className="flex-1 mx-2">
              <div className="bg-neutral-800 rounded-md shadow-md p-4 h-full">
                <div className="h-6 bg-neutral-700 rounded w-1/4 mb-4"></div>
                <div className="h-4 bg-neutral-700 rounded w-full mb-4"></div>
                <div className="h-4 bg-neutral-700 rounded w-5/6 mb-4"></div>
                <div className="h-4 bg-neutral-700 rounded w-3/4 mb-4"></div>
              </div>
            </div>
            
            {/* Right sidebar */}
            <div className="w-1/6 ml-2">
              <div className="h-full bg-neutral-800 rounded-md shadow-md p-4">
                <div className="h-4 bg-neutral-700 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-neutral-700 rounded w-1/2 mb-4"></div>
                <div className="h-4 bg-neutral-700 rounded w-5/6 mb-4"></div>
              </div>
            </div>
          </div>
        </div></div>)
       
        
          
      }
      
    return (
    <div>
        <Page article={contentData?.fields} code={contentData?.fields?.diagram?.code} diagram={contentData?.fields?.diagram?.diagram}  />
    </div>

)
}


export default DSADesc;