import React, { useRef, useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useLocation, useParams } from 'react-router-dom';
import right from '../right.svg';
import left from '../left.svg';
import {PanelLeftOpen,PanelRightOpen,Terminal,Binary,Braces,Ampersands}   from 'lucide-react'
import {useResearchDSAAPICallById} from "../RQuery";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import image from "../image.png";
import { _roots } from '@react-three/fiber';


function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener('change',listener);
    console.log(matches+"Here I am");
    return () => media.removeEventListener('change',listener);
  }, [matches, query]);

  return matches;
}
const Section = ({ content, progress, index, total }) => {

  const yRange = [(index-1) / total, (index+1) / total];
  console.log("yrange",yRange)

  const opacity = useTransform(progress, yRange, [0,1]);

  const y = useTransform(progress, yRange, ['-90%', '500%']);
  useEffect(()=>{

  },[yRange,opacity,y])
  return (
    <motion.div
      className="font-mono  font-semibold text-gray-800 absolute items-center justify-center "
      style={{y}}
    >
      <div  >
{/* need to change these value as per need!! */}
  <ul className='p-2 m-2 flex flex-wrap '>
  <li >{content.section}</li>
  </ul>  
    
      </div>

    </motion.div>
  );
};

const SyncedScrollComponent = (props) => {

  const isSmallScreen = useMediaQuery('(max-width: 640px)');
  const widthSize = () =>{
   
    return isSmallScreen  ? '100%': '16.67%';
    
  }



  const widthForSmallWindows = widthSize();
  console.log(widthForSmallWindows +"print this pelase")

  const containerRef = useRef(null);
  const sectionRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isSmallScreen);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(!isSmallScreen);
  const toggleRightSidebar = () => {
    setIsRightSidebarOpen(!isRightSidebarOpen);
  };
   
const [contentDatax,setContentDatax]  = useState(null);  
const { id } = useParams();
const { data: apiResponse, isLoading, isError, refetch } = useResearchDSAAPICallById(id);

const { scrollYProgress: sectionScrollProgress } = useScroll({ container: sectionRef, target:["0 1"] });
const [scrollProgress, setScrollProgress] = useState(useScroll({container:containerRef})); 
 console.log( "scroll ",sectionScrollProgress)
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  
  };
useEffect(()=>{
  console.log("api", apiResponse);
  if(apiResponse?.data){
    setContentDatax(apiResponse?.data?.fields);
    console.log("sample data ");
  }
},[apiResponse]);

useEffect(() => {
 setScrollProgress(sectionScrollProgress);

}, [sectionScrollProgress]);

useEffect(() => {
 
}, [scrollProgress]);
useEffect(() => {
  if (isSmallScreen) {
    setIsSidebarOpen(false);
    setIsRightSidebarOpen(false);
  }
}, [isSmallScreen]);


if (isLoading ) {

  return (   <div className="flex flex-col h-screen bg-neutral-800 text-gray-100 p-4">
    <motion.div
      className="bg-neutral-900 rounded-md shadow-md p-4 mb-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="h-8 bg-neutral-700 rounded-md mb-4 animate-pulse"></div>
      <div className="h-4 bg-neutral-700 rounded-md w-3/4 mb-2 animate-pulse"></div>
      <div className="h-4 bg-neutral-700 rounded-md w-1/2 animate-pulse"></div>
    </motion.div>

    <div className="flex flex-1">
      <motion.div
        className="w-1/6 bg-neutral-900 rounded-md shadow-md mr-4 animate-pulse"
        initial={{ opacity: 0, width: 0 }}
        animate={{ opacity: 1, width: "16.67%" }}
        transition={{ duration: 0.5 }}
      ></motion.div>

      <motion.div
        className="flex-1 bg-neutral-900 rounded-md shadow-md p-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {[...Array(5)].map((_, index) => (
          <div key={index} className="mb-4">
            <div className="h-4 bg-neutral-700 rounded-md w-full mb-2 animate-pulse"></div>
            <div className="h-4 bg-neutral-700 rounded-md w-3/4 animate-pulse"></div>
          </div>
        ))}
      </motion.div>

      <motion.div
        className="w-1/6 bg-neutral-900 rounded-md shadow-md ml-4 animate-pulse"
        initial={{ opacity: 0, width: 0 }}
        animate={{ opacity: 1, width: "16.67%" }}
        transition={{ duration: 0.5 }}
      ></motion.div>
    </div>
  </div>);
 
  
    
}

if (isError) {
  refetch();
  return <div className='p-16 m-16'>Error occurred while fetching data</div>;
}


  return (
    <div className="flex flex-col h-screen bg-neutral-800   text-gray-100 ">
      {/* Navbar */}
      <nav className="bg-neutral-900  text-gray-100  justify-start rounded-md shadow-md p-2 ">
        <h1 className=' text-2xl  items-center font-sans font-semibold flex   justify-between m-2  ml-24 '>
          
          

       
            <ul >{isSidebarOpen ? 'On This Page' : ''}</ul>
            <ul >{contentDatax?.title}</ul>
            <ul>{isRightSidebarOpen ? <div className='flex felx-wrap m-2 '> <Ampersands /><Binary /><Terminal/> <Braces /></div> : ''}</ul>
 
      
      </h1>
      </nav>

      {/* Main content */}
      <div  className={`flex   ${ !isSmallScreen ? 'pl-16' : 'pl-0'} pt-1  `}>
        {/* Side navbar */}

        <motion.div
          ref={containerRef}
          className={`
           bg-neutral-900  text-gray-200 
            h-full relative rounded-md shadow-md
            transition-all duration-300 ease-in-out
          `}
          initial={{width: isSmallScreen ? "0%" : "55.67%"  }}
          animate={{
            width: isSidebarOpen  ? widthForSmallWindows : "0%",
          }}
        >
          {/* Toggle button */}
          <motion.button
            style={{ zIndex: 40 }} className="absolute top-2 right-2 pt-3  sm:inline-block text-amber-400 font-bold p-2 rounded cursor-pointer"
            onClick={toggleSidebar} disabled={!isSidebarOpen}
          >
            {isSidebarOpen ?   <motion.div
      className="w-8 h-8 text-amber-400 "
      whileHover={{ scale: 1.2, rotate: 0 }}
      whileTap={{ scale: 0.8, rotate: 0, borderRadius: "100%" }}> <PanelRightOpen /></motion.div>: <div className=' cursor-not-allowed'></div>}
          </motion.button>

          <div className={`absolutes ${isSidebarOpen ? 'opacity-300' : 'opacity-0'} 
  justify-center transition-opacity duration-300 z-20 flex text-amber-500  `}>
            
            <div className='mt-16 font-extrabold' >
<div className='m-4 text-gray-200 font-medium '>
{documentToReactComponents(contentDatax?.leftsidenavbartext)}
</div>
            </div>
       
          </div>
        </motion.div>

        {/* Article section */}
        <motion.div
          ref={sectionRef}
          className=" z-0 "
          initial={{ width: isSmallScreen ? "100%" : "45.67%" }}
          animate={{
          width: isSidebarOpen && isRightSidebarOpen ? "65.66%" : 
           (isSidebarOpen || isRightSidebarOpen) ? "100%" : "100%"
          }}
        >
        {/* button are here */}
          <div className='flex justify-between sticky top-0 z-30 p-2 bg-gradient-to-b from-inherit to-transparent '>
  <motion.button
    className="  hover:text-white font-bold  sm:inline-block p-2 rounded cursor-pointer transition-colors duration-200"
    onClick={toggleSidebar}
  
  >
    {isSidebarOpen ? 
      <div className=' '></div> 
      : <motion.div
      className="w-8 h-8  sm:inline-block  "
      whileHover={{ scale: 1.2, rotate: 0 }}
      whileTap={{ scale: 0.8, rotate: 0, borderRadius: "100%" }}><PanelLeftOpen/></motion.div>}
  </motion.button>
  {/* // work here now */}
  { !isSmallScreen && <motion.button
    className={`  text-gray-600 hover:text-white font-bold p-2 rounded cursor-pointer transition-colors duration-200`} 
    onClick={toggleRightSidebar}
    disabled={isRightSidebarOpen}
  >
  
    {isRightSidebarOpen   ? 
      <div className='hover:disabled'></div> 
      : <motion.div
      className='w-8 h-8   sm:inline-block  text-amber-400   '
      whileHover={{ scale: 1.2, rotate: 0 }}
      whileTap={{ scale: 0.8, rotate: 0, borderRadius: "100%" }}> < PanelRightOpen /></motion.div>}
  </motion.button>}
</div>
          {/* Center article part */}
          
          <div className="h-screen justify-center p-4 m-8">


            {documentToReactComponents(contentDatax?.maintext)}

          </div>
          {/* Work here */}
         
        
        </motion.div>
        <motion.div 
          className="bg-neutral-700  text-amber-400  overflow-y-auto h-full relative rounded-md shadow-md transition-all duration-300 ease-in-out"
          initial={{ width: "0%" }}
          animate={{ width: isRightSidebarOpen ? "60.67%" : "0%" }}
        >
          <motion.button 
            style={{ zIndex: 40 }} 
            className="absolute top-2 left-2  sm:inline-block pt-3 text-white font-bold p-2 rounded cursor-pointer" 
            onClick={toggleRightSidebar} 
            disabled={!isRightSidebarOpen}
          >
            {isRightSidebarOpen ? <motion.div
      className="w-8 h-8 text-amber-400 "
      whileHover={{ scale: 1.2, rotate: 0 }}
      whileTap={{ scale: 0.8, rotate: 0, borderRadius: "100%" }}> <PanelLeftOpen/></motion.div> : <div className='bg-green-400 disabled:hover cursor-not-allowed'></div>}
          </motion.button>

          <div className={`relative ${isRightSidebarOpen ? 'opacity-300' : 'opacity-0'} justify-center transition-opacity duration-300 z-30 flex`}>
            {/* Add content for right sidebar here */}
            <div>
            {/* <span className='bg-gray-200 font-mono font-bold pt-10 mt-10 bg-opacity-75 opacity-100 '>Fun Facts on the Go 🚶‍♂️</span> */}
            <div className={`relative ${isRightSidebarOpen ? 'opacity-300' : 'opacity-0'} justify-center transition-opacity duration-300 z-10 flex h-auto`}>
       
    <React.Fragment> 
      <span></span>
  {contentDatax?.rightsidenavJSON?.map((item, index) => (
    
    
    <Section
      key={item.id}
      content={item}
      progress={sectionScrollProgress}
      index={index}
      total={contentDatax.rightsidenavJSON.length/2}
    />
    
  ))}
  </React.Fragment >
  {/* <div className='relative -bottom-64 left-0 w-full h-1/2 z-10 bg-transparent   '>
      <img className='relative w-full  h-1/4 z-20' src={image} />

    
  </div> */}

          </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

// Wrapper component with key for full remount on route change
const SyncedScrollWrapper = () => {
  const location = useLocation();
  return <SyncedScrollComponent  />;
};

export default SyncedScrollWrapper;