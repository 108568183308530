import React, {createContext, useState, useContext, useEffect} from "react";



const AppDataContext = createContext();

export const AppDataProvider =({children})=>{
    const [appData, setAppData]= useState(()=>{
        const storedData = sessionStorage.getItem('appData');
        return storedData? JSON.parse(storedData):[];
    });
    
    useEffect(()=>{
        if (!appData){
         
        }
      
    },[]);

    return (
        <AppDataContext.Provider value={{appData}}>
            {children}
        </AppDataContext.Provider>
    );
};

export const useAppData = () => useContext(AppDataContext);