
import Body from './Component/Body';
import { AppDataProvider } from './Element/Context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'


import HeaderComponent from "./Element/HeaderComponent";
function App() {

  const queryClient = new QueryClient();

  return (
 

      <div className='flex h-screen w-screen overflow-hidden'>
        <div className=' h-full'> 
      <HeaderComponent/>
      </div>
      <div className='flex-grow h-full w-screen overflow-hidden'>
      <QueryClientProvider client = {queryClient}>
      <AppDataProvider>
      <Body/>
      </AppDataProvider>      
      </QueryClientProvider>
      </div>
      
  
  
    </div>
  );
}

export default App;
